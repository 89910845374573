
import './hall-create.scss'

import rules from "@/utils/rules";

export default {
  data: () => ({
    ruleForm: {
      username: '',
      password: ''
    },
    rules
  }),
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  render() {
    return(
      <div class="system-import">
        <div class="hall-create-from">
          <el-form ref="ruleForm" props={{model: this.ruleForm}}>
            <el-form-item label="用户名" prop="username" rules={this.rules.fillOut}>
              <el-input v-model={this.ruleForm.username} placeholder="请输入您的用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" rules={this.rules.fillOut}>
              <el-input v-model={this.ruleForm.password} placeholder="请输入您的密码"></el-input>
            </el-form-item>
            <div class="create-from-submit">
              <el-button class="submit-btn" type="button" onClick={() => this.submitForm('ruleForm')}>确定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    )
  }
}
