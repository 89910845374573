
import './hall-create.scss'

import rules from "@/utils/rules";

export default {
  data: () => ({
    ruleForm: {
      region: '',
      name: '',
      phone: '',
      code: ''
    },
    checked: false,
    rules
  }),
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  render() {
    return(
      <div class="pavilion-created">
        <div class="create-title">填写手机验证后，将创建完成；建馆成功后，可在功能列表里完善资料</div>
        <div class="hall-create-from">
          <el-form ref="ruleForm" props={{ model: this.ruleForm }}>
            <el-form-item label="逝者安葬区域" prop="region" rules={this.rules.Change}>
              <el-select v-model={this.ruleForm.region} placeholder="请选择逝者安葬区域">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="逝者姓名" prop="name" rules={this.rules.Name}>
              <el-input v-model={this.ruleForm.name} placeholder="请输入逝者姓名"></el-input>
            </el-form-item>
            <el-form-item label="创建人手机号码" prop="phone" rules={this.rules.phone}>
              <el-input v-model={this.ruleForm.phone} placeholder="请输入您的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="短信验证码" prop="code" rules={this.rules.smsCode}>
              <el-input v-model={this.ruleForm.name} placeholder="请填写短信验证码"></el-input>
              <el-button class="create-from-btn">发送短信验证码</el-button>
            </el-form-item>
            <div class="create-from-deal">
              <el-checkbox v-model={this.checked}>我已阅读并同意遵守《网上纪念服务规则》</el-checkbox>
            </div>
            <div class="create-from-submit">
              <el-button class="submit-btn"  type="button"  onClick={() => this.submitForm('ruleForm')}>确定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    )
  }
}
