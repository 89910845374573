<template>
  <div class="hall-create">
    <div class="container">
      <main class="aside-box">
        <div class="create-main-title">
          <p>馆创建</p>
        </div>
        <div class="create-main-box">
          <component
           :is="createView"
          >
          </component>
        </div>
      </main>
      <aside class="blog_container_aside">
        <div id="asideProfile" class="aside-box">
          <div class="profile-avter"><img src="../../assets/images/hall/hall-index-banner.png" /></div>
          <div class="profile-name">很长的微信名</div>
          <p class="profile-tel">（18030121426）</p>
        </div>
        <div id="asideCreate" class="aside-box">
          <div class="create-title">纪念堂创建</div>
          <div class="create-content">
            <ul class="inf_list clearfix">
              <li class="clearfix"
                  v-for="(tab,index) in createList"
                  :key="index"
                  :class="[createIndex == index ? 'tab-active' : '']"
                  @click="changeCreate(tab,index)"
              >
                {{tab.name}}
              </li>
            </ul>
          </div>
          <div class="create-describe">
            <div class="describe-title"><span class="sea-iconfont icon-weizhi4"></span>领取纪念馆!严禁冒领</div>
            <p>
              填写右侧信息，系统将匹配相关数据，领取纪念馆。若领取纪念馆失败，或逝者安葬地不在以下墓园中，可点击
              <strong>馆创建</strong>自行建馆。
            </p>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
  import PavilionCreated from '@/components/hall-create/pavilion-created.js'
  import GetPavilion from '@/components/hall-create/get-pavilion.js'
  import SystemImport from '@/components/hall-create/system-import.js'
  import ClaimCode from '@/components/hall-create/claim-code.js'

  export default {
    name: 'hall-view',
    components: {
      PavilionCreated,
      GetPavilion,
      SystemImport,
      ClaimCode
    },
    data() {
      return {
        createView: PavilionCreated,
        createIndex: 3,
        createList: [{name: '纪念堂领取', component: GetPavilion}, {name: '领取码领取', component: ClaimCode}, {name: '旧系统导入', component: SystemImport}, {name: '馆创建', component: PavilionCreated}]
      }
    },
    methods: {
      changeCreate(tab, index) {
        this.createIndex = index;
        this.createView = tab.component
      }
    }
  }
</script>

<style lang="scss" scoped>
.hall-create {
  margin-top: 82px;
  width: 100%;
  position: relative;
  z-index: 2;
  .container {
    background: url("~@/assets/images/hall/create/hall-create-bg.png") no-repeat center;
    background-size: 100% 100%;
    .aside-box {
      background: #FFFFFF;
      border-radius: 10px;
    }
    main {
      float: right;
      display: block;
      width: 73%;
      height: 817px;
      margin-top: 26px;
      padding: 20px;
      .create-main-title {
        padding-bottom: 20px;
        border-bottom: 1px solid #E5E5E5;
        p{
          border-left: 3px solid #427EB4;
          padding-left: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #444444;
          line-height: 26px;
          margin: 0;
        }
      }
    }
    aside {
      width: 25%;
      float: left;
      display: block;
      #asideCreate {
        padding: 16px 9px 39px 9px;
        .create-describe {
          margin-left: 29px;
          margin-top: 79px;
          .describe-title {
            text-align: left;
            font-size: 21px;
            font-weight: 400;
            color: #444444;
            line-height: 27px;
            .sea-iconfont {
              color: rgba(255,156,0,1);
              margin-right: 6px;
              font-size: 24px;
            }
          }
          p {
            margin-top: 18px;
            text-align: left;
            letter-spacing: 1px;
            line-height: 24px;
            font-size: 16px;
            strong {
              color: rgba(255,156,0,1);
            }
          }
        }
        .create-content {
          margin-top: 20px;
          margin-left: 29px;
          overflow: hidden;
          ul {
            padding: 0;
            margin: 0;
            li {
              margin-bottom: 29px;
              font-size: 20px;
              font-weight: 400;
              color: #444444;
              line-height: 26px;
              cursor: pointer;
              &:hover {
                 color: #427FB3;
               }
              &:last-child {
                margin-bottom: 0;
               }
            }
            .tab-active {
              color: #427FB3;
            }
          }
        }
        .create-title {
          font-size: 24px;
          font-weight: bold;
          color: #444444;
          line-height: 26px;
          border-bottom: 1px solid #54A6D2;
          padding-bottom: 20px;
          padding-left: 21px;
        }
      }
      #asideProfile {
        margin: 26px 0 20px 0;
        padding: 20px;
        .profile-avter {
          text-align: center;
          img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .profile-name {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #6C6C6C;
          margin-top: 12px;
          line-height: 26px;
        }
        .profile-tel {
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #6C6C6C;
          line-height: 26px;
          margin: 0;
        }
      }
    }
    &::after{
      content: "";
      display: block;
      clear: both;
       height: 0;
       visibility: hidden;
     }
  }
}
</style>
