
import './hall-create.scss'

import rules from "@/utils/rules";

export default {
  data: () => ({
    ruleForm: {
      region: '',
      deadName: '',
      name: '',
      address: ''
    },
    checked: false,
    rules
  }),
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  render() {
    return(
      <div class="get-pavilion">
        <div class="hall-create-from">
          <el-form ref="ruleForm" props={{model: this.ruleForm}}>
            <el-form-item label="墓园" prop="region" rules={this.rules.Change}>
              <el-select v-model={this.ruleForm.region} placeholder="请选择逝者安葬（放）地点">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="位置" prop="address" rules={this.rules.fillOut}>
              <el-input v-model={this.ruleForm.address} placeholder="请输入安葬(放)证上的详细位置"></el-input>
            </el-form-item>
            <el-form-item label="逝者" prop="deadName" rules={this.rules.Name}>
              <el-input v-model={this.ruleForm.deadName} placeholder="请输入建馆逝者的姓名"></el-input>
            </el-form-item>
            <el-form-item label="持证人" prop="name" rules={this.rules.Name}>
              <el-input v-model={this.ruleForm.name} placeholder="请输入持证人的姓名"></el-input>
            </el-form-item>
            <div class="create-from-deal">
              <el-checkbox v-model={this.checked}>我已阅读并同意遵守《网上纪念服务规则》</el-checkbox>
            </div>
            <div class="create-from-submit">
              <el-button class="submit-btn" type="button" onClick={() => this.submitForm('ruleForm')}>确定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    )
  }
}
