
import './hall-create.scss'

import rules from "@/utils/rules";

export default {
  data: () => ({
    ruleForm: {
      hallNo: '',
      hallCode: ''
    },
    rules
  }),
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  render() {
    return(
      <div class="claim-code">
        <div class="hall-create-from">
          <el-form ref="ruleForm" props={{model: this.ruleForm}}>
            <el-form-item label="馆号" prop="hallNo" rules={this.rules.fillOut}>
              <el-input v-model={this.ruleForm.hallNo} placeholder="请输入纪念馆馆号"></el-input>
            </el-form-item>
            <el-form-item label="领取码" prop="hallCode" rules={this.rules.fillOut}>
              <el-input v-model={this.ruleForm.hallCode} placeholder="请输入领取码"></el-input>
            </el-form-item>
            <div class="create-from-submit">
              <el-button class="submit-btn" type="button" onClick={() => this.submitForm('ruleForm')}>确定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    )
  }
}
